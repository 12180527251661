@import '../abstracts/variables';

.panel-itech {
	// background-color: var(--main-background);
	color: var(--main-background-contrast);
	// filter: brightness(110%);
	//overflow: hidden;
	padding: 0.5rem 0.5rem;
	@include shadow-1;
	//margin: 15px 0;
	flex: 1;

	.form-control {
		background: var(--form-background-color);

		&:disabled, &[readonly] {
			background: var(--form-background-color-disabled);
			color: var(--form-background-color-disabled-contrast);
		}
	}
}
