.font-1rem {
	font-size: 1rem;
}

.font-875rem {
	font-size: .875rem;
}

.font-8125rem {
    font-size: .8125rem;
}

.font-weight-500 {
	font-weight: 500;
}

.no-select {
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}