@import 'header';
@import 'modal';
@import 'pageTop';
@import 'side-panel';
@import 'sidebar';
@import 'tabs';

@include scrollbars(.5em, #d9d9d9, rgba(0, 0, 0, 0));

html {
	position: relative;
	min-width: 320px;
}

html, body {
	min-height: 100vh;
	min-width: $resMin;
}

//body {
//  font: 14px/16px $font-family;
//  color: $default-text;
//  background-color: $body-bg;
//  &.blur-theme {
//    @include main-background();
//  }
//}

//body.mobile{
//  background: none;
//  .body-bg{
//    display: block;
//    position: fixed;
//    top: 0;
//    left: 0;
//    bottom: 0;
//    right: 0;
//    background-attachment: inherit;
//    background-color: $body-bg;
//  }
//  &.blur-theme .body-bg{
//    @include main-background();
//  }
//}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	html {
		overflow: hidden;
		height: 100%;
	}
	body {
		overflow: auto;
		height: 100%;
	}
}

a {
	@include transition($transitionTime, ease, color, background-color);
	outline: 0 !important;
}

.body-bg {
	display: none;
}

.al-header {
	display: block;
	height: 49px;
	margin: 0;
	background-repeat: repeat-x;
	position: relative;
	z-index: 905;
	color: #444444;
}

.al-main {
	//margin-left: $sidebar-width;
	display: flex;
	flex: 1;
	//padding: $top-height 0 $footer-height 0;
	min-height: 100%;
	//width: 100%;
	//width: calc(100% - #{$sidebar-width});
	position: relative;
	overflow: auto;
}

.al-footer {
	height: $footer-height;
	padding: 0 18px 0 $sidebar-width;
	width: 100%;
	position: absolute;
	display: block;
	bottom: 0;
	font-size: 13px;
	color: $default-text;
	@include transition(0.5s, ease, padding-left);
}

.al-footer-main {
	float: left;
	margin-left: 15px;
}

.al-footer-right {
	float: right;
	margin-right: 12px;

	i {
		margin: 0 4px;
		color: $danger;
		font-size: 12px;
	}

	a {
		margin-left: 4px;
		color: $default-text;

		&:hover {
			color: $danger;
		}
	}
}

.al-share {
	margin: -6px 0 0 12px;
	padding: 0;
	list-style: none;
	float: left;

	li {
		list-style: none;
		float: left;
		margin-left: 16px;

		i {
			cursor: pointer;
			transition: all 0.1s ease;
			color: white;
			padding: 6px;
			box-sizing: content-box;
			font-size: 16px;

			&:hover {
				transform: scale(1.2);
			}
		}

		i.fa-facebook-square {
			color: $facebook-color;
		}

		i.fa-twitter-square {
			color: $twitter-color;
		}

		i.fa-google-plus-square {
			color: $google-color;
		}
	}
}

.al-content {
	width: 100%;
	//margin-top: 10px;
    padding: 10px;
	position: relative;
	overflow-x: hidden;
}

@media screen and (max-width: $resXS) {
	.al-content {
		padding: 5px 10px;
	}
}

.icon-up, .icon-down {
	width: 5px;
	height: 13px;
	display: block;
}

//.icon-up {
//  @include bg-nr('arrow-green-up.svg');
//}
//
//.icon-down {
//  @include bg-nr('arrow-red-down.svg');
//}

.disable-text-selection {
	-webkit-touch-callout: none;
	user-select: none;
}

.align-right {
	text-align: right
}

.amcharts-chart-div > a {
	font-size: 6px !important;
}

.content-panel {
	padding-left: 22px;
	padding-top: 26px;
}

@media (max-width: 590px) {
	.al-footer-right {
		float: none;
		margin-bottom: 19px;
		margin-right: 0;
	}
	.al-footer {
		height: 76px;
		text-align: center;
	}
	.al-main {
		padding-bottom: 76px;
	}
	.al-footer-main {
		float: none;
		display: inline-block;
	}
}

.full-invisible {
	visibility: hidden !important;

	* {
		visibility: hidden !important;
	}
}

.irs-grid-text {
	color: $default-text;
}

.viewer-wrapper {
	position: absolute;
	width: 100%;
	left: 0;
	top: var(--top-height);
	height: calc(100% - var(--top-height));
	max-height: calc(100% - var(--top-height));
	overflow: auto;
}

.col-1p5 {
	-ms-flex: 0 0 12.5%;
	flex: 0 0 12.5%;
	max-width: 12.5%;
}
.col-10p5 {
	-ms-flex: 0 0 87.5%;
	flex: 0 0 87.5%;
	max-width: 87.5%;
}

.col-1p5, .col-10p5 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}

.over-x-hidden {
	overflow-x: hidden;
}

.over-y-auto {
	overflow-y: auto;
}

.over-y-hidden {
	overflow-y: hidden;
}

.pos-reset {
	top: 0;
	left: 0;
}