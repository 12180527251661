@import "../abstracts/variables";

$angle-left: "\f100";
$angle-right: "\f101";

.sidebar {
	width: var(--sidebar-width);
	// top: var(--top-height);
	// height: calc(100vh - var(--top-height));
	// left: 0;
	z-index: 904;
	display: flex;
	background-color: var(--sidebar-background);
	// font-size: 16px;
	box-shadow: var(--shadow);
	// border-top: 1px solid var(--border-color);
	// border-top: 0;
	//height: 100%;
	//position: fixed;

	@include transition($transitionTime, ease, width);

	&.collapse {
		display: flex;
		width: var(--sidebar-width-collapse) !important;

		+ .main {
			width: calc(100% - var(--sidebar-width-collapse));
		}

		.sidebar-list-item:not(:hover) {
			width: $sidebar-width-collapse !important;
		}
	}

	& .toggle-collapse {
		width: 100%;
		background-color: var(--main-color);
		color: var(--default-text);
		border-radius: 0;

		&:hover {
			background-color: var(--sidebar-active-background);
		}

		&:focus {
			box-shadow: none;
		}
	}

}

.sidebar-heading{ // keep this for compatibility
    height: 32px;
    color: var(--sidebar-background-contrast);
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    background-color: var(--sidebar-background);
}

.sidebar-header{
	height: 32px;
	// border-top: 3px solid var(--main-color-dark);
    // border-bottom: 1px solid var(--border-color);
    color: var(--sidebar-header-color);
    background-color: var(--sidebar-background);
	padding-left: 8px!important;
	padding-right: 12px!important;
	// margin-bottom: 4px;
}

.collapse .sidebar-header{
	padding-right: 0px!important;
	padding-left: 0px!important;
}

.btn-icon-toggle {
	// background: var(--main-color-dark);
	color: var(--text-color);
	width: 22px;
	height: 22px;
	padding: 0;

	&:hover {
		color: var(--text-highlight-color) !important;
		background-color: var(--sidebar-active-background);
	}
}

.collapse {
	.btn-icon-toggle {
		height: 32px !important;
		width: 30px !important;
	}
}

.sidebar-list {
	margin: 0;
	padding: 0;
	list-style: none;
	// text-transform: uppercase;
}

/*.sidebar-sublist .subitem-submenu-list {
  padding-left: 15px;
}*/

.subitem-submenu-link {
	.fa {
		top: 7px;
	}
}

.sidebar-list-item {
	font-size: 14px;
	display: block;
	position: relative;
	float: none;
	padding: 0 8px;
	max-width: var(--sidebar-width);
	color: var(--text-color);
	@include transition(var(--transitionTime), ease, width);

	&.selected:not(.with-sub-menu) {
		background-color: var(--sidebar-active-background);

		a.sidebar-list-link {
			color: var(--text-highlight-color);

			b {
				color: var(--text-highlight-color);
			}

			i::before{
				color: var(--text-highlight-color);
			}		
		}
	}

	&, .sidebar.collapse & {
		a.sidebar-list-link {
			&.selected {
				background-color: var(--sidebar-active-background);
				color: var(--text-highlight-color);

				&:hover {
					background-color: var(--sidebar-active-background);
				}

				i::before, svg{
				color: var(--text-highlight-color);
				}		
			}
		}
	}

	.sidebar.collapse & {
		padding: 0;
	}

	&.with-sub-menu {
		&:not(.ba-sidebar-item-expanded) {
			& .sidebar-list-link.selected {
				background-color: var(--sidebar-active-background);
				color: var(--text-color);

				&:hover {
					background-color: var(--sidebar-active-background);
					// color: var(--text-highlight-color);
				}
			}
		}

		.sidebar.collapse & {
			& .sidebar-sublist {
				pointer-events: none;
				cursor: default;
			}

			&:hover .sidebar-sublist {
				opacity: 1;
				position: absolute;
				top: 0;
				left: var(--sidebar-width-collapse);
				border-left: 1px solid white;
				background-color: var(--sidebar-active-background);
				width: var(--sidebar-width);
				z-index: 100;
				height: auto;
				pointer-events: inherit;
				cursor: pointer;

				.sidebar-list-link {
					padding-left: var(--sidebar-item-pad-left);
				}
			}
		}
	}

	i::before, svg{
		color: var(--text-color);
	}

	@media (max-width: 1024px) {
		padding-left: 0px !important;
	}

	@media (max-width: 767px) {
		padding-left: 0 !important;
	}
}

.sidebar-list-item, .ba-sidebar-sublist-item {
	&.ba-sidebar-item-expanded {
		> .sidebar-list-link b {
			i, svg {
				transform: rotate(180deg);
			}
		}

		> .sidebar-sublist {
			display: block;
		}
	}
}

a.sidebar-list-link {
	display: block;
	height: var(--sidebar-item-height);
	padding-left: var(--sidebar-item-pad-left);
	text-shadow: none;
	text-decoration: none;
	color: var(--text-color);
	line-height: var(--sidebar-item-height);
	white-space: nowrap;
	overflow: hidden;
	cursor: pointer;
	@include transition (var(--transitionTime), ease, width);

	&:hover {
		background-color: var(--sidebar-active-background);
		// color: var(--text-highlight-color);

		i::before, svg {
			// color: var(--text-highlight-color);
		}
		// b {
			//background-color: $green-itech-light;
		// }
	}

	& i, & svg {
		margin-right: var(--sidebar-item-pad-left);
		width: 1rem !important;
		display: inline-block;
		@include transition(0.2s, linear, transform);
	}

	b {
		display: block;
		opacity: 1;
		width: 14px;
		height: 18px;
		line-height: 14px;
		text-shadow: none;
		font-size: 16px;
		position: absolute;
		right: 16px;
		top: calc((var(--sidebar-item-height) - 18px) /2);
		padding: 0;
		text-align: center;
		color: var(--default-text);
		//transition: transform 0.2s linear;
		@include transition(var(--transitionTime), ease, visibility);

		.sidebar.collapse & {
			visibility: hidden;
		}
	}
}

.slimScrollBar, .slimScrollRail {
	border-radius: 0 !important;
	width: 4px !important;
	left: 176px;
}

@mixin layout-collapsed() {
	//.main {
	//  margin-left: $sidebar-width-collapse;
	//}

	.footer {
		padding-left: 83px
	}
}

@mixin default-sublist() {
	padding: 0;
	list-style: none;
	position: relative;
	height: auto;

	@include transition(var(--transitionTime), ease, height, opacify);

	&:not(.expanded), .sidebar.collapse & {
		height: 0;
		opacity: 0;
		pointer-events: none;
		cursor: default;
		@include transition(var(--transitionTime), ease, height);
	}

	&.expanded {
		//visibility: visible;
		opacity: 1;
	}
	> li {
		display: block;
		float: none;
		padding: 0;
		border-bottom: none;
		position: relative;

		a {
			display: block;
			text-shadow: none;
			font-size: 14px;
			text-decoration: none;
			color: var(--text-color);
			padding-left: var(--sidebar-width-collapse);
			height: var(--sidebar-item-height);
			line-height: var(--sidebar-item-height);

			&:hover {
				background-color: var(--sidebar-active-background) !important;
				// color: var(--text-highlight-color);
			}
		}

		&.selected:not(.with-sub-menu) > a {
			border: none;
			background-color: var(--sidebar-active-background) !important;
			color: var(--text-highlight-color);

			&:hover {
				// color: var(--text-highlight-color);
			}
		}

		&:not(.with-sub-menu) {
			a.sidebar-list-link {
				&.selected {
					background-color: var(--sidebar-active-background);
					color: var(--text-highlight-color);
				}
			}
		}
	}
}

.sidebar-sublist {
	@include default-sublist();
}

.sidebar-hover-elem {
	width: 4px;
	background: $main-color-hover;
	position: absolute;
	top: -150px;
	left: var(--sidebar-width) - 4px;
	@include transition(var(--transitionTime), ease, all);
	//transition: all 0.5s ease;
	//transition-property: top, height;
	height: var(--sidebar-item-height);
	display: block;
}

.sidebar-select-elem {
	display: block;
	top: 94px;
}

@mixin sidebar-collapsed() {
	.sidebar {
		width: var(--sidebar-width-collapse);

		.fa-angle-down, .fa-angle-up {
			opacity: 0;
		}

		.sidebar-sublist {
			position: absolute;
			top: -1px;
			left: var(--sidebar-width-collapse);
			@include bg-translucent-dark(0.8);
			width: 0;
			display: block;
			overflow: hidden;
			transition: width 0.5s ease;

			&.slide-right {
				width: 135px;
			}

			&:before {
				display: none;
			}

			li {
				&:before {
					display: none;
				}

				a {
					padding-left: var(--sidebar-item-pad-left);
					padding-right: var(--sidebar-item-pad-left);
					min-width: 130px;
					white-space: nowrap;
				}
			}
		}

		.sidebar-hover-elem, .sidebar-select-elem {
			left: 48px;
		}
	}
}

@mixin sidebar-hidden() {
	.sidebar {
		width: 0;
	}
	.sidebar-hover-elem, .sidebar-select-elem {
		display: none;
	}
}

@mixin sidebar-overlap() {
	.sidebar {
		width: var(--sidebar-width);
		@include bg-translucent-dark(0.75);
		transition: width 0.5s ease;

		.fa-angle-down, .fa-angle-up {
			opacity: 1;
		}

		.sidebar-sublist {
			@include default-sublist();
			top: auto;
			left: auto;
			background: none;
			width: auto;
			overflow: visible;
			transition: none;
		}

		.sidebar-hover-elem, .sidebar-select-elem {
			left: var(--sidebar-width) - 4;
			transition: left 0.5s ease;
		}
	}
}

@media (min-width: 1200px) {
	.menu-collapsed {
		@include layout-collapsed();
	}
}

@media (min-width: $resXS + 1) {
	.menu-collapsed {
		@include sidebar-collapsed();
	}
}

@media (max-width: 1200px) and (min-width: $resXS) {
	@include layout-collapsed();
}

//@media (max-width: 1200px) {
//  @include sidebar-overlap();
//}

@media (max-width: $resXS) {
	.menu-collapsed {
		@include sidebar-hidden();
	}
	.main {
		margin-left: 0;
	}
	.footer {
		padding-left: 0;
	}
}
