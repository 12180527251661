@import "../abstracts/variables";

.msg-box {

	& .msg-body {
		border-radius: 0.5rem;
		border: solid 1px;
		padding: 1.2rem;
		text-align: justify;
		min-height: 150px;
		color: var(--modal-itech-background-contrast);
	}

	&-warning {
		& .msg-head {
			color: var(--warning-head);
		}

		& .msg-body {
			border-color: var(--warning-border);
			background-color: var(--warning-background);
			color: var(--text-color);
		}
	}

	&-info {
		& .msg-head {
			color: var(--main-color-dark);
		}

		& .msg-body {
			border-color: var(--main-color-dark);
			background-color: var(--content-background);
			color: var(--text-color);
		}
	}
}

#closeMsg {
	svg {
		margin-left: .5rem;
		//background-color: var(--cyan);
		color: var(--cyan);

		&:hover {
			border-radius: 59%;
			background-color: var(--cyan);
			color: white;
		}
	}
}