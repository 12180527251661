@import '../abstracts/variables';

.version {
	z-index: 9999;
	color: var(--white);
	font-size: small;
	line-height: normal;
	position: fixed;
	left: 0;
	bottom: 0;
	padding: .2rem;
}
