@import '../abstracts/variables';

.pagination-itech {
  .page-item {
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 0.25rem;
    }

    .page-link {
      width: var(--paginationBtnWidth);
      height: var(--paginationBtnHeight);
      line-height: var(--paginationBtnHeight);
      text-align: center;
      padding: 0;
      color: var(--cyan);
      background: linear-gradient(180deg, #FFFFFF 0%, #F9F9F9 100%);
      border-radius: .25rem;

      &:hover {
        background: var(--main-color);
        color: var(--white);
        border-color: var(--main-color);
      }
    }

    &.active {
      .page-link {
        background: var(--main-color);
        color: var(--white);
        border-color: var(--main-color);
      }
    }
  }
}