.image-attachment {
  width: 100px;
  height: 100px;
  cursor: pointer;
  margin: 0 .5rem;
}

.image-attachment.big {
  width: 500px;
  height: 500px;
  margin: 0;
}

.image-attachment.small {
  width: 25px;
  height: 25px;
}

.preview-attachment {
  height: calc(100% - 2 * 1.75rem);
  max-width: max-content;
}

.preview-attachment .modal-content {
  height: 75%;
  background: var(--modal-itech-background);
}

.btn-delete-attachment {
  position: absolute;
  top: 0;
  right: .5rem;
  color: white;
  padding: .2rem;
}

.btn-delete-attachment:hover {
  color: grey;
}

.image-attachment-report {
  height: 60px;
  cursor: pointer;
  object-fit: contain;
  margin: 0.5rem 1rem 0.5rem 0;
}

.image-attachment-report.big {
  height: 500px;
  margin: 0;
}

/*============================================
            BUTTON ON NAVBAR
============================================*/
@media (max-width: 767px) {
  .simple-btn-keyboard-nav a,
  .simple-btn-keyboard-nav div[uib-dropdown] {
    padding: 10px 15px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .simple-btn-keyboard-nav a,
  .simple-btn-keyboard-nav div[uib-dropdown] {
    padding: 22px 15px !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .simple-btn-keyboard-nav a,
  .simple-btn-keyboard-nav div[uib-dropdown] {
    padding: 22px 15px !important;
  }
}

@media (min-width: 1200px) {
  .simple-btn-keyboard-nav a,
  .simple-btn-keyboard-nav div[uib-dropdown] {
    padding: 22px 15px !important;
  }
}

/*============================================
            BUTTON RE-DESIGN
============================================*/
.btn-shadow {
  box-shadow: var(--shadow);
}

.btn-itech {
  border-radius: 5px !important;
  color: var(--default-text) !important;
  font-weight: 500;
  box-shadow: var(--shadow);
  white-space: nowrap;
}

.btn-itech:focus {
  box-shadow: none;
}

.btn-itech[disabled] {
  pointer-events: none;
}

.btn-itech-fixed {
  min-width: 129px;
}

.btn-itech-sm {
  min-width: 75px !important;
  margin: 0;
}

.btn-itech-md {
  min-width: 100px !important;
  margin: 0;
}

.btn-itech-xl {
  min-width: 129px !important;
  margin: 0;
}

.btn-itech-icon {
  height: 26px;
  width: 26px;
  padding: unset !important;
  margin: 0 auto;
  border-radius: 99px !important;
  display: flex;
  background-color: var(--button-icon-color);
}

.btn-itech-icon svg, .btn-itech-icon i {
  margin: auto;
  font-size: 14px;
}

.btn-itech-icon-primary {
  box-shadow: none;
  color: var(--default-text) !important;
  background-color: var(--button-icon-color);
}

.btn-itech-icon-primary:hover, .btn-itech-icon-primary:active, .btn-itech-icon-primary.active {
  filter: brightness(110%);
}

.btn-itech-icon-warning {
  box-shadow: none;
  background: transparent !important;
}

.btn-itech-icon-warning:hover, .btn-itech-icon-warning:active, .btn-itech-icon-warning.active {
  background: var(--orange) !important;
}

.btn-itech-icon-secondary {
  box-shadow: none;
  color: var(--default-text) !important;
}

.btn-itech-icon-secondary:hover, .btn-itech-icon-secondary:active, .btn-itech-icon-secondary.active {
  filter: brightness(110%);
}

.btn-itech-primary {
  background: var(--button-color-primary) !important;
}

.btn-itech-primary i::before {
  color: white;
}

.btn-itech-primary:hover {
  filter: brightness(110%);
}

.btn-itech-secondary {
  background: var(--button-color-secondary) !important;
}

.btn-itech-secondary:hover {
  filter: brightness(110%);
}

.btn-itech-dark {
  background: var(--main-color-dark) !important;
}

.btn-itech-dark:hover {
  filter: brightness(110%);
}

.btn-itech-light {
  background: var(--main-color-light);
}

.btn-itech-light:hover {
  filter: brightness(110%);
}

.btn-itech-light:active {
  filter: brightness(110%);
}

.btn-itech-danger {
  background: var(--orange);
  color: var(--white) !important;
}

.btn-itech-danger:hover {
  color: var(--white);
  background-color: var(--orange-light-1);
}

.btn-itech-danger:active {
  background: var(--grey-1);
}

.btn-itech-dropdown {
  background: var(--white);
  border: 1px solid #ced4da;
  white-space: nowrap;
}

.btn-itech-dropdown:hover {
  border-color: var(--main-color-light);
}

.btn-itech-dropdown:focus {
  border-color: var(--main-color-dark);
  background-color: var(--form-background-color-focus) !important;
}

.btn-itech-delete {
  background: var(--main-color);
}

.btn-itech-delete:hover {
  color: var(--white);
  filter: brightness(110%);
}

.btn-itech-delete:active {
  filter: brightness(110%);
}

.btn-pick-date {
  height: 30px;
  background: var(--button-color-primary) !important;
  color: var(--default-text) !important;
  flex: 1 1 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.btn-pick-date.active {
  background-color: var(--button-color-secondary);
  filter: brightness(125%);
  color: var(--default-text) !important;
  z-index: 0 !important;
}

.btn-itech-outline {
  height: 30px;
  border: 3px solid transparent;
  border-radius: 25px;
  color: var(--text-color) !important;
  flex: 1 1 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0;
}

.btn-itech-outline.active {
  border: 3px solid var(--button-color-primary);
  border-radius: 25px;
  padding: 0;
  color: var(--text-color) !important;
  z-index: 0 !important;
}

.group-btn-pick-date {
  display: flex;
}

.group-btn-pick-date > button {
  margin-left: .15rem;
  margin-right: .15rem;
  flex-grow: 1;
}

.group-btn-pick-date > button:first-child {
  margin-left: 0 !important;
}

.group-btn-pick-date > button:last-child {
  margin-right: 0 !important;
}

.btn:disabled {
  pointer-events: none;
}

.btn-link {
  color: var(--text-color-link);
}

.radius-0 {
  border-radius: 0 !important;
}

.radius-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.radius-bot-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

div.react-datepicker-wrapper, div.react-datepicker__input-container {
  width: 100%;
}

div.react-datepicker__day--selected {
  background-color: var(--main-color-dark);
}

.delete-box {
  position: absolute;
  height: var(--delete-box-height);
  width: 100%;
  bottom: 0;
  background: var(--cyan);
  color: var(--white);
  left: 0;
}

.has-delete-box {
  margin-bottom: var(--delete-box-height);
}

.download-tracking {
  position: fixed;
  bottom: 0;
  left: 0;
  max-width: var(--sidebar-width);
  padding: 1.5rem .5rem;
  transition: left 200ms linear;
}

.download-tracking.collapse {
  display: block;
}

.download-tracking > button {
  white-space: pre-wrap;
}

.btn-dropdown {
  background: var(--form-background-color) !important;
  color: var(--form-background-color-contrast);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(69, 95, 109, 0.5);
}

.btn-dropdown:hover, .btn-dropdown:focus, .btn-dropdown:active {
  background-color: var(--grey-normal) !important;
}

.btn-dropdown.dropdown-toggle {
  background: var(--white);
}

.dropdown-itech a {
  display: block;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: var(--text-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-itech a button, .dropdown-itech a div {
  color: var(--text-color);
}

.dropdown-itech a:hover, .dropdown-itech a:active, .dropdown-itech a:active, .dropdown-itech a:focus {
  background-color: var(--dropdown-item-background-hover);
  color: var(--default-text);
}

.dropdown-itech a:hover button, .dropdown-itech a:hover div, .dropdown-itech a:active button, .dropdown-itech a:active div, .dropdown-itech a:active button, .dropdown-itech a:active div, .dropdown-itech a:focus button, .dropdown-itech a:focus div {
  color: var(--default-text);
}

.dropdown-item.disabled, .dropdown-item:disabled {
  pointer-events: none;
  opacity: .65;
  color: var(--text-color);
}

.flex-1 {
  flex: 1;
}

.flex-0 {
  flex: 1 1 0px;
}

.field-inline div.ra-field > div {
  width: 100%;
  flex-direction: row;
  margin-top: 0;
  margin-bottom: 0;
}

.field-inline div.ra-field > div label {
  margin-bottom: 0;
  padding: 0;
  transform: none;
  line-height: inherit;
}

.field-inline div.ra-field > div > div {
  width: auto;
  padding: 0;
  margin-left: 1rem;
  transform: none;
}

.select-dicom-files > label {
  display: none;
}

.select-dicom-files > span {
  width: 100%;
}

.select-dicom-files .previews {
  display: none;
  flex-wrap: wrap;
  justify-content: space-between;
}

.select-study-type [role='radiogroup'] {
  display: flex;
  flex-direction: row;
}

.select-study-type [role='radiogroup'] label > span {
  height: auto;
}

.font-1rem {
  font-size: 1rem;
}

.font-875rem {
  font-size: .875rem;
}

.font-8125rem {
  font-size: .8125rem;
}

.font-weight-500 {
  font-weight: 500;
}

.no-select {
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}

.form-control {
  background-color: var(--form-background-color);
  color: var(--text-color);
  border: none;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  padding: 3px 8px;
}

.form-control:hover {
  border-color: var(--main-color-hover);
}

.form-control:focus {
  border-color: var(--main-color-dark);
  box-shadow: none;
  background-color: var(--form-background-color-focus) !important;
  color: var(--text-color) !important;
}

.form-control:disabled {
  background-color: var(--form-background-color-disabled) !important;
  color: var(--form-background-color-disabled-contrast) !important;
}

.form-control-sm {
  font-size: 14px;
  height: 30px;
  border: 1px solid var(--border-color);
}

.form-group {
  margin-bottom: 0.3rem;
}

.was-validated .form-control:valid ~ .invalid-feedback {
  display: none;
}

.input-group-itech-search .input-group-prepend .input-group-text {
  border-radius: 99px 0 0 99px;
  background: var(--form-background-color);
  border: var(--form-background-color);
}

.input-group-itech-search .form-control {
  border-radius: 0 99px 99px 0;
  height: 30px !important;
}

.hide-legend legend, .hide-legend p {
  display: none;
}

.file-input p {
  margin: auto;
}

.file-input div {
  padding: 0;
}

.report-builder-form > div {
  width: 100%;
  padding-left: 0;
}

.custom-control-label:before {
  background-color: var(--form-background-color);
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--form-background-color);
  border-color: var(--border-color);
}

.custom-radio-hidden {
  position: absolute;
  opacity: 0;
}

.custom-radio {
  display: inline-block;
  position: relative;
}

.custom-radio input[type='radio'] {
  display: none;
}

.custom-radio label {
  font-weight: normal;
  font-size: 14px;
}

.custom-radio label:before {
  content: " ";
  display: inline-block;
  position: relative;
  margin: 0 5px 0 0;
  width: 14px;
  height: 14px;
  border-radius: 11px;
  border: 2px solid var(--text-color);
  background-color: transparent;
}

.custom-radio input[type=radio]:checked + label:after {
  border-radius: 11px;
  width: 8px;
  height: 8px;
  position: absolute;
  left: 3px;
  content: " ";
  display: inline-block;
  background: var(--main-color-dark);
}

.col-form-label {
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
  color: var(--text-color);
}

.label-filter {
  font-size: var(--fontSize875);
  padding-bottom: 0.25rem;
}

.label-filter-sm {
  font-size: var(--fontSize875);
  padding-top: 0;
  padding-bottom: 0;
}

.label-color {
  color: var(--text-color);
}

.label-info {
  font-size: 1.125rem !important;
  overflow-wrap: break-word;
}

.info-title-label {
  font-size: .8rem;
  text-transform: uppercase;
}

.label-header-xl {
  font-size: 24px;
  color: var(--text-header-color) !important;
  font-weight: 500;
}

.label-header-lg {
  font-size: 20px;
  color: var(--text-header-color) !important;
  font-weight: 500;
}

.label-header-md {
  font-size: 16px;
  color: var(--text-header-color) !important;
  font-weight: 500;
}

.label-header-sm {
  font-size: 14px;
  color: var(--text-header-color) !important;
  font-weight: 500;
}

.logo {
  content: url("/images/logo-medium.png");
}

@media (max-width: 767px) {
  .logo {
    content: url("/images/logo-small.png") !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .logo {
    content: url("/images/logo-small.png") !important;
  }
}

.msg-box .msg-body {
  border-radius: 0.5rem;
  border: solid 1px;
  padding: 1.2rem;
  text-align: justify;
  min-height: 150px;
  color: var(--modal-itech-background-contrast);
}

.msg-box-warning .msg-head {
  color: var(--warning-head);
}

.msg-box-warning .msg-body {
  border-color: var(--warning-border);
  background-color: var(--warning-background);
  color: var(--text-color);
}

.msg-box-info .msg-head {
  color: var(--main-color-dark);
}

.msg-box-info .msg-body {
  border-color: var(--main-color-dark);
  background-color: var(--content-background);
  color: var(--text-color);
}

#closeMsg svg {
  margin-left: .5rem;
  color: var(--cyan);
}

#closeMsg svg:hover {
  border-radius: 59%;
  background-color: var(--cyan);
  color: white;
}

.bg-light {
  background: var(--white) !important;
}

.nav-tabs {
  border: none;
}

.navbar.nav-iTech {
  background: var(--navbar-background);
  padding: 0 1rem;
}

.navbar.nav-iTech .logo {
  height: 40px;
}

.nav-item {
  user-select: none;
  height: var(--tab-height);
  border-radius: 0;
  background: var(--tab-background) !important;
  border-right: 1px solid var(--tab-border-color);
  border-top: 1px solid var(--tab-border-color);
}

.nav-item a {
  color: var(--text-color);
  -webkit-user-drag: none;
}

.nav-item a:hover {
  background-color: var(--tab-active-background);
}

.nav-item:hover {
  background-color: var(--tab-active-background) !important;
}

.nav-item .nav-link {
  border-radius: 0;
  height: var(--tab-height);
}

.nav-item .nav-link:hover {
  border-radius: 0;
  background-color: var(--tab-active-background);
  border-color: var(--tab-active-background);
  color: var(--text-color);
}

.nav-item .nav-link.active {
  border-radius: 0;
  background-color: var(--tab-active-background);
  border-color: var(--tab-active-background);
  color: var(--text-color);
  font-weight: 700;
}

#navItech {
  flex: 1;
  flex-wrap: nowrap;
  overflow-x: overlay;
  overflow-y: hidden;
  position: relative;
}

#navItech .tab-close svg {
  margin-left: .5rem;
  color: var(--cyan);
}

#navItech .tab-close svg:hover {
  border-radius: 59%;
  background-color: var(--cyan);
  color: white;
}

#navItech::-webkit-scrollbar {
  width: 1vw;
  background: transparent;
}

#navItech::-webkit-scrollbar-track, #navItech::-webkit-scrollbar-track-piece {
  background: transparent;
}

.nav.nav-pills.nav-iTech a {
  color: var(--main-color);
}

.nav.nav-pills.nav-iTech a:hover {
  color: var(--main-color-hover);
  background-color: var(--green-input-form-selection);
}

.nav.nav-pills.nav-iTech a.active {
  color: white;
  background-color: var(--main-color);
}

.nav.nav-pills.nav-iTech a.active :hover {
  background-color: var(--green-input-form-selection);
}

.pagination-itech .page-item {
  cursor: pointer;
}

.pagination-itech .page-item:not(:last-child) {
  margin-right: 0.25rem;
}

.pagination-itech .page-item .page-link {
  width: var(--paginationBtnWidth);
  height: var(--paginationBtnHeight);
  line-height: var(--paginationBtnHeight);
  text-align: center;
  padding: 0;
  color: var(--cyan);
  background: linear-gradient(180deg, #FFFFFF 0%, #F9F9F9 100%);
  border-radius: .25rem;
}

.pagination-itech .page-item .page-link:hover {
  background: var(--main-color);
  color: var(--white);
  border-color: var(--main-color);
}

.pagination-itech .page-item.active .page-link {
  background: var(--main-color);
  color: var(--white);
  border-color: var(--main-color);
}

.panel-itech {
  color: var(--main-background-contrast);
  padding: 0.5rem 0.5rem;
  -webkit-box-shadow: 10px 10px 15px -20px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 10px 10px 15px -20px rgba(0, 0, 0, 0.6);
  box-shadow: 10px 10px 15px -20px rgba(0, 0, 0, 0.6);
  flex: 1;
}

.panel-itech .form-control {
  background: var(--form-background-color);
}

.panel-itech .form-control:disabled, .panel-itech .form-control[readonly] {
  background: var(--form-background-color-disabled);
  color: var(--form-background-color-disabled-contrast);
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: var(--main-color);
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px var(--main-color), 0 0 5px var(--main-color);
  opacity: 1.0;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: var(--main-color);
  border-left-color: var(--main-color);
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner, .nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.scroll-sm ::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.scroll-secondary ::-webkit-scrollbar-thumb {
  background: var(--main);
  cursor: pointer;
}

.scroll-secondary ::-webkit-scrollbar-track {
  background: var(--main-background);
}

.scroll-secondary {
  scrollbar-face-color: var(--main);
  scrollbar-track-color: var(--main-background);
}

.table {
  -webkit-box-shadow: 10px 10px 15px -20px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 10px 10px 15px -20px rgba(0, 0, 0, 0.6);
  box-shadow: 10px 10px 15px -20px rgba(0, 0, 0, 0.6);
}

.table thead.table-itech-thread tr {
  background: var(--main-background) !important;
  color: var(--text-color) !important;
}

.table thead.table-itech-thread tr th {
  font-weight: 700 !important;
  text-align: left;
}

.table thead.table-itech-thread th,
.table thead.table-itech-thread td,
.table thead.table-itech-thread span {
  background-color: var(--main-background);
  color: var(--text-color) !important;
  font-weight: 700 !important;
  font-size: .875rem;
}

.table-striped tbody tr {
  /*&:hover {
				background: $main-color-light-1;
				color: var(--white);
				box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.2);
			}*/
}

.table-striped tbody tr:nth-of-type(odd) {
  background: var(--tableOdd);
}

.table-striped tbody tr:nth-of-type(odd):hover:not(.active) {
  background: var(--table-hover-row) !important;
}

.table-striped tbody tr:nth-of-type(even):not(.active) {
  background: var(--tableEven);
}

.table-striped tbody tr:nth-of-type(even):hover:not(.active) {
  background: var(--table-hover-row) !important;
}

.table-striped tbody tr td:has(button.btn-itech-icon) {
  padding: 0 !important;
  background: black !important;
}

.table-striped tbody tr td:has(div) > div {
  background: #dbdbdb;
}

.hide-bulk-actions [data-test="bulk-actions-toolbar"] {
  display: none;
}

.version {
  z-index: 9999;
  color: var(--white);
  font-size: small;
  line-height: normal;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: .2rem;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--main-background);
  background-clip: border-box;
  border: 1px solid var(--border-color);
  border-radius: .25rem;
}

@media (max-width: 767px) {
  #user {
    padding: 10px 15px 0 15px;
    line-height: 20px !important;
    margin-right: 0 !important;
  }
}

.modal-itech-background {
  background: var(--black-half-opacity);
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 9999;
}

.modal-itech {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--white);
  box-shadow: var(--shadow);
  width: 50vw;
  font-size: 14px;
}

.modal-itech .modal-itech-header {
  height: 66px;
  background: var(--text-color);
  color: var(--white);
  padding: 15px;
  text-transform: uppercase;
  text-align: center;
}

.modal-itech .modal-itech-body {
  padding: 20px 40px 30px 40px;
}

.modal-itech .modal-itech-body .modal-panel {
  background: var(--main-background);
  padding: 15px 15px 15px 20px;
}

.modal-itech .modal-itech-body .modal-panel label,
.modal-itech .modal-itech-body .modal-panel i,
.modal-itech .modal-itech-body .modal-panel span {
  line-height: 38px;
}

.modal-header {
  background: var(--modal-itech-background);
  color: var(--modal-itech-background-contrast);
}

.modal-header .close {
  color: var(--modal-itech-background-contrast);
}

.modal-content {
  background: var(--modal-itech-background);
  color: var(--modal-itech-background-contrast);
}

@media (max-width: 1024px) {
  .modal-itech {
    width: 100vw;
  }
  .modal-itech .modal-itech-body {
    padding: 20px;
  }
}

.modal-pushing {
  width: 610px;
  height: 535px;
}

.modal-pushing .modal-pushing-header {
  height: 25%;
  text-align: center;
  padding: 30px;
}

.modal-pushing .modal-pushing-body {
  height: 50%;
  display: block;
  background: var(--modal-itech-background);
  overflow-y: scroll;
  padding: 35px 30px;
}

.modal-pushing .modal-pushing-body ul {
  list-style-type: none;
  padding: 0;
}

.modal-pushing .modal-pushing-body ul .progress-itech {
  height: 6px;
  width: 50%;
}

.modal-pushing .modal-pushing-body ul .progress-itech .progress-bar-itech {
  height: 6px;
}

.modal-pushing .modal-pushing-footer {
  height: 25%;
}

@media (max-width: 610px) {
  .modal-pushing {
    width: 90%;
  }
}

.page-top {
  background-color: var(--main-color);
  position: fixed;
  z-index: 904;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
  height: var(--top-height);
  width: 100%;
  min-width: 320px;
}

.page-top.scrolled {
  background-color: rgba(0, 0, 0, 0.85);
}

a.al-logo {
  color: var(--default-text);
  display: block;
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  white-space: nowrap;
  float: left;
  outline: none !important;
  line-height: 60px;
}

a.al-logo span {
  color: #6c7770;
}

.user-profile {
  float: right;
  min-width: 230px;
  margin-top: 10px;
}

.al-user-profile {
  float: right;
  margin-right: 12px;
  transition: all .15s ease-in-out;
  padding: 0;
  width: 36px;
  height: 36px;
  border: 0;
  opacity: 1;
  position: relative;
}

.al-user-profile a {
  display: block;
}

.al-user-profile img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

a.refresh-data {
  color: var(--default-text);
  font-size: 13px;
  text-decoration: none;
  font-weight: 400;
  float: right;
  margin-top: 13px;
  margin-right: 26px;
}

a.refresh-data:hover {
  color: #dfb81c !important;
}

a.collapse-menu-link {
  font-size: 31px;
  cursor: pointer;
  display: block;
  text-decoration: none;
  line-height: 42px;
  color: var(--default-text);
  padding: 0;
  float: left;
  margin: 11px 0 0 25px;
}

a.collapse-menu-link:hover {
  text-decoration: none;
  color: #dfb81c;
}

.al-skin-dropdown {
  float: right;
  margin-top: 14px;
  margin-right: 26px;
}

.al-skin-dropdown .tpl-skin-panel {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.search {
  text-shadow: none;
  color: var(--default-text);
  font-size: 13px;
  line-height: 25px;
  transition: all 0.5s ease;
  white-space: nowrap;
  overflow: hidden;
  width: 162px;
  float: left;
  margin: 20px 0 0 30px;
}

.search label {
  cursor: pointer;
}

.search i {
  width: 16px;
  display: inline-block;
  cursor: pointer;
  padding-left: 1px;
  font-size: 16px;
  margin-right: 13px;
}

.search input {
  background: none;
  border: none;
  outline: none;
  width: 120px;
  padding: 0;
  margin: 0 0 0 -3px;
  height: 27px;
}

@media screen and (max-width: 660px) {
  .search {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .page-top {
    padding: 0 20px;
  }
}

@media (max-width: 435px) {
  .user-profile {
    min-width: 136px;
  }
  a.refresh-data {
    margin-right: 10px;
  }
  a.collapse-menu-link {
    margin-left: 10px;
  }
  .al-skin-dropdown {
    display: none;
  }
}

.background_rectangle_sidemenu {
  position: absolute;
  width: 20%;
  height: 1080px;
  background-color: var(--grey-dark);
  box-shadow: var(--shadow);
}

.header_logo {
  position: absolute;
  left: 1%;
  top: 90px;
}

.top_navigation_arrow {
  position: absolute;
  top: 50px;
  left: 16.5%;
  color: var(--white);
  opacity: 0;
}

.top_navigation_arrow:hover {
  opacity: 0.5;
}

ul.side-menu-itech {
  position: absolute;
  top: 175px;
  width: 20%;
}

ul.side-menu-itech li {
  padding-top: 25px;
}

ul.dropdown-menu.side-sub-menu-itech {
  position: absolute;
  top: 205px;
  width: 100%;
  background-color: transparent;
  z-index: 1;
}

ul.dropdown-menu.side-sub-menu-itech li {
  padding-top: 50px;
}

.sidebar {
  width: var(--sidebar-width);
  z-index: 904;
  display: flex;
  background-color: var(--sidebar-background);
  box-shadow: var(--shadow);
  transition: width 0s ease;
  -webkit-transition: width 0s ease;
  -moz-transition: width 0s ease;
  -o-transition: width 0s ease;
}

.sidebar.collapse {
  display: flex;
  width: var(--sidebar-width-collapse) !important;
}

.sidebar.collapse + .main {
  width: calc(100% - var(--sidebar-width-collapse));
}

.sidebar.collapse .sidebar-list-item:not(:hover) {
  width: 30px !important;
}

.sidebar .toggle-collapse {
  width: 100%;
  background-color: var(--main-color);
  color: var(--default-text);
  border-radius: 0;
}

.sidebar .toggle-collapse:hover {
  background-color: var(--sidebar-active-background);
}

.sidebar .toggle-collapse:focus {
  box-shadow: none;
}

.sidebar-heading {
  height: 32px;
  color: var(--sidebar-background-contrast);
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  background-color: var(--sidebar-background);
}

.sidebar-header {
  height: 32px;
  color: var(--sidebar-header-color);
  background-color: var(--sidebar-background);
  padding-left: 8px !important;
  padding-right: 12px !important;
}

.collapse .sidebar-header {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.btn-icon-toggle {
  color: var(--text-color);
  width: 22px;
  height: 22px;
  padding: 0;
}

.btn-icon-toggle:hover {
  color: var(--text-highlight-color) !important;
  background-color: var(--sidebar-active-background);
}

.collapse .btn-icon-toggle {
  height: 32px !important;
  width: 30px !important;
}

.sidebar-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

/*.sidebar-sublist .subitem-submenu-list {
  padding-left: 15px;
}*/
.subitem-submenu-link .fa {
  top: 7px;
}

.sidebar-list-item {
  font-size: 14px;
  display: block;
  position: relative;
  float: none;
  padding: 0 8px;
  max-width: var(--sidebar-width);
  color: var(--text-color);
  transition: width var(--transitionTime) ease;
  -webkit-transition: width var(--transitionTime) ease;
  -moz-transition: width var(--transitionTime) ease;
  -o-transition: width var(--transitionTime) ease;
}

.sidebar-list-item.selected:not(.with-sub-menu) {
  background-color: var(--sidebar-active-background);
}

.sidebar-list-item.selected:not(.with-sub-menu) a.sidebar-list-link {
  color: var(--text-highlight-color);
}

.sidebar-list-item.selected:not(.with-sub-menu) a.sidebar-list-link b {
  color: var(--text-highlight-color);
}

.sidebar-list-item.selected:not(.with-sub-menu) a.sidebar-list-link i::before {
  color: var(--text-highlight-color);
}

.sidebar-list-item a.sidebar-list-link.selected, .sidebar.collapse .sidebar-list-item a.sidebar-list-link.selected {
  background-color: var(--sidebar-active-background);
  color: var(--text-highlight-color);
}

.sidebar-list-item a.sidebar-list-link.selected:hover, .sidebar.collapse .sidebar-list-item a.sidebar-list-link.selected:hover {
  background-color: var(--sidebar-active-background);
}

.sidebar-list-item a.sidebar-list-link.selected i::before, .sidebar-list-item a.sidebar-list-link.selected svg, .sidebar.collapse .sidebar-list-item a.sidebar-list-link.selected i::before, .sidebar.collapse .sidebar-list-item a.sidebar-list-link.selected svg {
  color: var(--text-highlight-color);
}

.sidebar.collapse .sidebar-list-item {
  padding: 0;
}

.sidebar-list-item.with-sub-menu:not(.ba-sidebar-item-expanded) .sidebar-list-link.selected {
  background-color: var(--sidebar-active-background);
  color: var(--text-color);
}

.sidebar-list-item.with-sub-menu:not(.ba-sidebar-item-expanded) .sidebar-list-link.selected:hover {
  background-color: var(--sidebar-active-background);
}

.sidebar.collapse .sidebar-list-item.with-sub-menu .sidebar-sublist {
  pointer-events: none;
  cursor: default;
}

.sidebar.collapse .sidebar-list-item.with-sub-menu:hover .sidebar-sublist {
  opacity: 1;
  position: absolute;
  top: 0;
  left: var(--sidebar-width-collapse);
  border-left: 1px solid white;
  background-color: var(--sidebar-active-background);
  width: var(--sidebar-width);
  z-index: 100;
  height: auto;
  pointer-events: inherit;
  cursor: pointer;
}

.sidebar.collapse .sidebar-list-item.with-sub-menu:hover .sidebar-sublist .sidebar-list-link {
  padding-left: var(--sidebar-item-pad-left);
}

.sidebar-list-item i::before, .sidebar-list-item svg {
  color: var(--text-color);
}

@media (max-width: 1024px) {
  .sidebar-list-item {
    padding-left: 0px !important;
  }
}

@media (max-width: 767px) {
  .sidebar-list-item {
    padding-left: 0 !important;
  }
}

.sidebar-list-item.ba-sidebar-item-expanded > .sidebar-list-link b i, .sidebar-list-item.ba-sidebar-item-expanded > .sidebar-list-link b svg, .ba-sidebar-sublist-item.ba-sidebar-item-expanded > .sidebar-list-link b i, .ba-sidebar-sublist-item.ba-sidebar-item-expanded > .sidebar-list-link b svg {
  transform: rotate(180deg);
}

.sidebar-list-item.ba-sidebar-item-expanded > .sidebar-sublist, .ba-sidebar-sublist-item.ba-sidebar-item-expanded > .sidebar-sublist {
  display: block;
}

a.sidebar-list-link {
  display: block;
  height: var(--sidebar-item-height);
  padding-left: var(--sidebar-item-pad-left);
  text-shadow: none;
  text-decoration: none;
  color: var(--text-color);
  line-height: var(--sidebar-item-height);
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  transition: width var(--transitionTime) ease;
  -webkit-transition: width var(--transitionTime) ease;
  -moz-transition: width var(--transitionTime) ease;
  -o-transition: width var(--transitionTime) ease;
}

a.sidebar-list-link:hover {
  background-color: var(--sidebar-active-background);
}

a.sidebar-list-link i, a.sidebar-list-link svg {
  margin-right: var(--sidebar-item-pad-left);
  width: 1rem !important;
  display: inline-block;
  transition: transform 0.2s linear;
  -webkit-transition: transform 0.2s linear;
  -moz-transition: transform 0.2s linear;
  -o-transition: transform 0.2s linear;
}

a.sidebar-list-link b {
  display: block;
  opacity: 1;
  width: 14px;
  height: 18px;
  line-height: 14px;
  text-shadow: none;
  font-size: 16px;
  position: absolute;
  right: 16px;
  top: calc((var(--sidebar-item-height) - 18px) /2);
  padding: 0;
  text-align: center;
  color: var(--default-text);
  transition: visibility var(--transitionTime) ease;
  -webkit-transition: visibility var(--transitionTime) ease;
  -moz-transition: visibility var(--transitionTime) ease;
  -o-transition: visibility var(--transitionTime) ease;
}

.sidebar.collapse a.sidebar-list-link b {
  visibility: hidden;
}

.slimScrollBar, .slimScrollRail {
  border-radius: 0 !important;
  width: 4px !important;
  left: 176px;
}

.sidebar-sublist {
  padding: 0;
  list-style: none;
  position: relative;
  height: auto;
  transition: all var(--transitionTime) ease;
  -webkit-transition: all var(--transitionTime) ease;
  -moz-transition: all var(--transitionTime) ease;
  -o-transition: all var(--transitionTime) ease;
  transition-property: height, opacify;
}

.sidebar-sublist:not(.expanded), .sidebar.collapse .sidebar-sublist {
  height: 0;
  opacity: 0;
  pointer-events: none;
  cursor: default;
  transition: height var(--transitionTime) ease;
  -webkit-transition: height var(--transitionTime) ease;
  -moz-transition: height var(--transitionTime) ease;
  -o-transition: height var(--transitionTime) ease;
}

.sidebar-sublist.expanded {
  opacity: 1;
}

.sidebar-sublist > li {
  display: block;
  float: none;
  padding: 0;
  border-bottom: none;
  position: relative;
}

.sidebar-sublist > li a {
  display: block;
  text-shadow: none;
  font-size: 14px;
  text-decoration: none;
  color: var(--text-color);
  padding-left: var(--sidebar-width-collapse);
  height: var(--sidebar-item-height);
  line-height: var(--sidebar-item-height);
}

.sidebar-sublist > li a:hover {
  background-color: var(--sidebar-active-background) !important;
}

.sidebar-sublist > li.selected:not(.with-sub-menu) > a {
  border: none;
  background-color: var(--sidebar-active-background) !important;
  color: var(--text-highlight-color);
}

.sidebar-sublist > li:not(.with-sub-menu) a.sidebar-list-link.selected {
  background-color: var(--sidebar-active-background);
  color: var(--text-highlight-color);
}

.sidebar-hover-elem {
  width: 4px;
  background: #079a48;
  position: absolute;
  top: -150px;
  left: var(--sidebar-width)-4px;
  transition: all var(--transitionTime) ease;
  -webkit-transition: all var(--transitionTime) ease;
  -moz-transition: all var(--transitionTime) ease;
  -o-transition: all var(--transitionTime) ease;
  height: var(--sidebar-item-height);
  display: block;
}

.sidebar-select-elem {
  display: block;
  top: 94px;
}

@media (min-width: 1200px) {
  .menu-collapsed .footer {
    padding-left: 83px;
  }
}

@media (min-width: 501px) {
  .menu-collapsed .sidebar {
    width: var(--sidebar-width-collapse);
  }
  .menu-collapsed .sidebar .fa-angle-down, .menu-collapsed .sidebar .fa-angle-up {
    opacity: 0;
  }
  .menu-collapsed .sidebar .sidebar-sublist {
    position: absolute;
    top: -1px;
    left: var(--sidebar-width-collapse);
    background: rgba(0, 0, 0, 0.8);
    width: 0;
    display: block;
    overflow: hidden;
    transition: width 0.5s ease;
  }
  .menu-collapsed .sidebar .sidebar-sublist.slide-right {
    width: 135px;
  }
  .menu-collapsed .sidebar .sidebar-sublist:before {
    display: none;
  }
  .menu-collapsed .sidebar .sidebar-sublist li:before {
    display: none;
  }
  .menu-collapsed .sidebar .sidebar-sublist li a {
    padding-left: var(--sidebar-item-pad-left);
    padding-right: var(--sidebar-item-pad-left);
    min-width: 130px;
    white-space: nowrap;
  }
  .menu-collapsed .sidebar .sidebar-hover-elem, .menu-collapsed .sidebar .sidebar-select-elem {
    left: 48px;
  }
}

@media (max-width: 1200px) and (min-width: 500px) {
  .footer {
    padding-left: 83px;
  }
}

@media (max-width: 500px) {
  .menu-collapsed .sidebar {
    width: 0;
  }
  .menu-collapsed .sidebar-hover-elem, .menu-collapsed .sidebar-select-elem {
    display: none;
  }
  .main {
    margin-left: 0;
  }
  .footer {
    padding-left: 0;
  }
}

.itech-tabs {
  background-color: var(--main-color);
  height: var(--top-height)1px;
  width: 100%;
  min-width: 320px;
  flex: 1;
}

.itech-tabs .nav-link {
  height: var(--tab-height);
  text-decoration: none;
  color: var(--default-text);
  border-radius: 0;
  border: none;
  padding: 0.5rem 0.75rem;
  margin-top: var(--top-height)-var(--tab-height);
}

.itech-tabs .nav-link:hover {
  color: var(--main-color-dark);
}

.itech-tabs .nav-link.active {
  background-color: var(--main-color-dark);
  color: var(--default-text);
}

.itech-tabs .nav-link.active .tab-close svg:hover {
  background-color: var(--main-color-light);
  color: var(--sidebar);
}

.itech-tabs .nav-link:last-child {
  margin-left: auto;
}

.itech-tabs .nav-link .tab-close svg {
  margin-left: 0.75rem;
  color: var(--default-text);
}

.itech-tabs .nav-link .tab-close svg:hover {
  border-radius: 50%;
  background-color: var(--sidebar);
  color: var(--main-color-light);
}

.nav-iTech .nav-link {
  padding: .3rem .5rem;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-basis: auto;
  flex-grow: 1;
  min-width: 100px;
  max-width: 160px;
}

.nav-iTech .tab-close {
  display: flex;
}

.nav-iTech .tab-close .tab-title {
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nav-iTech .nav-item a {
  border-bottom: 1px solid var(--tab-border-color) !important;
  line-height: 1;
}

.no-wrap {
  white-space: nowrap;
}

::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

html {
  position: relative;
  min-width: 320px;
}

html, body {
  min-height: 100vh;
  min-width: 320px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html {
    overflow: hidden;
    height: 100%;
  }
  body {
    overflow: auto;
    height: 100%;
  }
}

a {
  transition: all 0s ease;
  -webkit-transition: all 0s ease;
  -moz-transition: all 0s ease;
  -o-transition: all 0s ease;
  transition-property: color, background-color;
  outline: 0 !important;
}

.body-bg {
  display: none;
}

.al-header {
  display: block;
  height: 49px;
  margin: 0;
  background-repeat: repeat-x;
  position: relative;
  z-index: 905;
  color: #444444;
}

.al-main {
  display: flex;
  flex: 1;
  min-height: 100%;
  position: relative;
  overflow: auto;
}

.al-footer {
  height: 34px;
  padding: 0 18px 0 270px;
  width: 100%;
  position: absolute;
  display: block;
  bottom: 0;
  font-size: 13px;
  color: #ffffff;
  transition: padding-left 0.5s ease;
  -webkit-transition: padding-left 0.5s ease;
  -moz-transition: padding-left 0.5s ease;
  -o-transition: padding-left 0.5s ease;
}

.al-footer-main {
  float: left;
  margin-left: 15px;
}

.al-footer-right {
  float: right;
  margin-right: 12px;
}

.al-footer-right i {
  margin: 0 4px;
  color: #ed3333;
  font-size: 12px;
}

.al-footer-right a {
  margin-left: 4px;
  color: #ffffff;
}

.al-footer-right a:hover {
  color: #ed3333;
}

.al-share {
  margin: -6px 0 0 12px;
  padding: 0;
  list-style: none;
  float: left;
}

.al-share li {
  list-style: none;
  float: left;
  margin-left: 16px;
}

.al-share li i {
  cursor: pointer;
  transition: all 0.1s ease;
  color: white;
  padding: 6px;
  box-sizing: content-box;
  font-size: 16px;
}

.al-share li i:hover {
  transform: scale(1.2);
}

.al-share li i.fa-facebook-square {
  color: #3b5998;
}

.al-share li i.fa-twitter-square {
  color: #55acee;
}

.al-share li i.fa-google-plus-square {
  color: #dd4b39;
}

.al-content {
  width: 100%;
  padding: 10px;
  position: relative;
  overflow-x: hidden;
}

@media screen and (max-width: 500px) {
  .al-content {
    padding: 5px 10px;
  }
}

.icon-up, .icon-down {
  width: 5px;
  height: 13px;
  display: block;
}

.disable-text-selection {
  -webkit-touch-callout: none;
  user-select: none;
}

.align-right {
  text-align: right;
}

.amcharts-chart-div > a {
  font-size: 6px !important;
}

.content-panel {
  padding-left: 22px;
  padding-top: 26px;
}

@media (max-width: 590px) {
  .al-footer-right {
    float: none;
    margin-bottom: 19px;
    margin-right: 0;
  }
  .al-footer {
    height: 76px;
    text-align: center;
  }
  .al-main {
    padding-bottom: 76px;
  }
  .al-footer-main {
    float: none;
    display: inline-block;
  }
}

.full-invisible {
  visibility: hidden !important;
}

.full-invisible * {
  visibility: hidden !important;
}

.irs-grid-text {
  color: #ffffff;
}

.viewer-wrapper {
  position: absolute;
  width: 100%;
  left: 0;
  top: var(--top-height);
  height: calc(100% - var(--top-height));
  max-height: calc(100% - var(--top-height));
  overflow: auto;
}

.col-1p5 {
  -ms-flex: 0 0 12.5%;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.col-10p5 {
  -ms-flex: 0 0 87.5%;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}

.col-1p5, .col-10p5 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.over-x-hidden {
  overflow-x: hidden;
}

.over-y-auto {
  overflow-y: auto;
}

.over-y-hidden {
  overflow-y: hidden;
}

.pos-reset {
  top: 0;
  left: 0;
}

.main-page {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.bg-color {
  background: var(--main-background);
}

.bg-color-sidebar {
  background: var(--sidebar-background);
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 1rem !important;
  color: #58585a;
  background: var(--main-background);
}

.clickable {
  cursor: pointer;
}

.row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.label-required::after {
  color: red;
  content: '*';
}

.btn-icon[itech-toggle-menu] {
  background-color: #333333;
  color: #fff;
}

.btn-icon[itech-toggle-menu]:hover, .btn-icon[itech-toggle-menu]:active {
  background-color: #6c7770;
  color: #fff;
}

.text-left {
  text-align: left !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto", sans-serif;
  margin-top: 0;
}

:root {
  font-size: 100%;
  --black: black;
  --grey-normal-opacity: rgba(102, 102, 102, 0.3);
  --grey-normal-opacity-hover: rgba(102, 102, 102, 0.2);
  --grey-dark: #333333;
  --red: red;
  --default-text: #ffffff;
  --form-background-color: rgba(33, 156, 119, 0.1);
  --form-background-color-focus: rgba(33, 156, 119, 0.2);
  --text-color: #58585a;
  --grey-background: #E5E5E5;
  --modal-itech-background: #F7F9FC;
  --main-color: #8ec540;
  --main-color-hover: #079a48;
  --main-color-light: #6c7770;
  --main-color-light-1: #079a48;
  --main-color-dark: #4c4c4c;
  --green-text-selection: #76B194;
  --green-input-form-selection: rgba(197, 202, 202, 0.1);
  --cyan: #455F6D;
  --cyan-lighter: #6a7e8a;
  --grey-light: #F7F7F7;
  --main-background: #ffffff;
  --grey-1: #cccccc;
  --grey-2: #e0e0e0;
  --black-opacity: rgba(0, 0, 0, 0.1);
  --orange: #F79D49;
  --orange-light-1: #f8b06d;
  --white: #fff;
  --info: #2dacd1;
  --success: #248037;
  --warning: #dfb81c;
  --danger: #ed3333;
  --facebook-color: #3b5998;
  --twitter-color: #55acee;
  --google-color: #dd4b39;
  --linkedin-color: #0177B5;
  --github-color: #6b6b6b;
  --stackoverflow-color: #2F96E8;
  --dribble-color: #F26798;
  --behace-color: #0093FA;
  --sidebar-item-height: 32px;
  --sidebar-width: 270px;
  --sidebar-width-collapse: 30px;
  --sidebar-item-pad-left: 8px;
  --warning-border: rgba(219, 11, 11, 0.2);
  --warning-background: rgba(219, 11, 11, 0.05);
  --warning-head: #db0b0b;
  --top-height: 50px;
  --tab-height: 35px;
  --footer-height: 34px;
  --delete-box-height: 80px;
  --transitionTime: 0s;
  --paginationBtnWidth: 32px;
  --paginationBtnHeight: 32px;
  --fontSize875: 14px;
  --shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
  --font-family: Roboto, sans-serif;
  --font-thin: 100;
  --font-light: 300;
  --font-normal: 400;
  --font-bold: 700;
  --font-ultraBold: 900;
}

:root body {
  margin: 0;
  font-size: 1rem;
}

.create-page > div:first-child, .edit-page > div:first-child {
  height: 100%;
  overflow: auto;
  margin-top: 0 !important;
}

.simple-form > div:first-child {
  height: 100%;
}

.h-0 {
  height: 0 !important;
}

.itech-tooltip {
  word-wrap: break-word;
  pointer-events: none;
}

/*# sourceMappingURL=main.css.map */