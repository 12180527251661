@mixin bg-translucent-dark($opacity) {
  background: rgba(0, 0, 0, $opacity);
}

@mixin scrollbars-size($size) {
  ::-webkit-scrollbar {
    width: $size;
    height: $size;
  }
}

@mixin scrollbars-color($foreground-color, $background-color) {
  ::-webkit-scrollbar-thumb {
    background: $foreground-color;
    cursor: pointer;
  }

  ::-webkit-scrollbar-track {
    background: $background-color;
  }

  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
  @include scrollbars-size($size);

  @include scrollbars-color($foreground-color, $background-color);
}

@mixin transition($time, $type, $prop...) {
  @if (length($prop) == 1) {
    transition: $prop $time $type;
    -webkit-transition: $prop $time $type;
    -moz-transition: $prop $time $type;
    -o-transition: $prop $time $type;
    //-ms-transition: $prop $time $type;
  } @else {
    transition: all $time $type;
    -webkit-transition: all $time $type;
    -moz-transition: all $time $type;
    -o-transition: all $time $type;
    //-ms-transition: all $time $type;
    transition-property: $prop;
  }
}


