$black: black;
$red: red;

// --------------------- NEW COLOR RE-DESIGN------------------------
$form-background-color: rgba(33, 156, 119, 0.1);
$form-background-color-focus:rgba(33, 156, 119, 0.2);
$default-text: #ffffff;
$default-text-selected: #ffffff;
$modal-itech-background:  #F7F9FC;
$main-color-light: #6c7770;
$main-color-light-1: #079a48;
$main-color-dark: #4c4c4c;
$grey-dark: #333333;
$grey-background: #E5E5E5; //Main background - Background Dropdown
$grey-normal-opacity: rgba(102,102,102,0.3);
$grey-normal-opacity-hover:rgba(102,102,102,0.2);
$green-text-selection: #76B194;
$green-input-form-selection: rgba(197, 202, 202, 0.1);
$cyan: #455F6D;
$cyan-lighter: #6a7e8a;
$grey-light: #F7F7F7;
$grey-1: #CCCCCC;
$grey-2: #E0E0E0;
$black-opacity: rgba(0,0,0, 0.1);
$orange: #F79D49;
$orange-light-1: #f8b06d;

$white: #fff;

//$primary: #209e91 !default;
$info: #2dacd1 !default;
$success: #248037 !default;
$warning: #dfb81c !default;
$danger: #ed3333 !default;

$facebook-color: #3b5998;
$twitter-color: #55acee;
$google-color: #dd4b39;
$linkedin-color: #0177B5;
$github-color: #6b6b6b;
$stackoverflow-color: #2F96E8;
$dribble-color: #F26798;
$behace-color: #0093FA;

// updated theme
$main-color: #8ec540;
$main-color-hover: #079a48;
$table-color-light: #ffffff;
$table-color-light-2: #f7f9fa;
$table-color-dark: #2a2c2f;
$table-color-dark-2: #000000;
$main-background: #ffffff;

$text-color: #58585a;

// $sidebar: $main-color;
// $sidebar-heading: $main-color-dark;
// $sidebar-text: $default-text;
// $sidebar-text-selected: $default-text;
$sidebar-item-height: 32px;
$sidebar-width: 270px;
$sidebar-width-collapse: 30px;
$sidebar-item-pad-left: 8px;

$warning-border: rgba(219, 11, 11, 0.2);
$warning-background: rgba(219, 11, 11, 0.05);
$warning-head: rgba(219, 11, 11, 1);

$top-height: 50px;
//$top-height: 42px;
$tab-height: 35px;
$footer-height: 34px;
$delete-box-height: 80px;


$transitionTime: 0.0s;

//pagination
$paginationBtnWidth: 32px;
$paginationBtnHeight: 32px;

$fontSize875: 14px;

// ---------------------SHADOW------------------------

$shadow:  0 1px 5px 0 rgba(0,0,0,0.2), 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12);

@mixin shadow-1 {
  -webkit-box-shadow: 10px 10px 15px -20px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 10px 10px 15px -20px rgba(0, 0, 0, 0.6);
  box-shadow: 10px 10px 15px -20px rgba(0, 0, 0, 0.6);
}

@mixin shadow-2 {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}


// ---------------------DIRECTORY PATH----------------
$font-family: 'Roboto', sans-serif;
$font-thin: 100;
$font-light: 300;
$font-normal: 400;
$font-bold: 700;
$font-ultraBold: 900;
