@import "../abstracts/variables";

.itech-tabs {
	background-color: var(--main-color);
	height: var(--top-height) + 1px;
	width: 100%;
	min-width: $resMin;
	flex: 1;

	& .nav-link {
		height: var(--tab-height);
		text-decoration: none;
		color: var(--default-text);
		border-radius: 0;
		border: none;
		padding: 0.5rem 0.75rem;
		margin-top: var(--top-height) - var(--tab-height);

		&:hover {
			color: var(--main-color-dark);
		}

		&.active {
			background-color: var(--main-color-dark);
			color: var(--default-text);

			.tab-close {
				svg:hover {
					background-color: var(--main-color-light);
					color: var(--sidebar);
				}
			}
		}

		&:last-child {
			margin-left: auto;
		}

		.tab-close {
			svg {
				margin-left: 0.75rem;
				color: var(--default-text);

				&:hover {
					border-radius: 50%;
					background-color: var(--sidebar);
					color: var(--main-color-light);
				}
			}
		}
	}
}

.nav-iTech {
	.nav-link {
        padding: .3rem .5rem;
		font-size: 14px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		flex-basis: auto;
		flex-grow: 1;
		min-width: 100px;
		max-width: 160px;
	}

	.tab-close {
		display: flex;

		.tab-title {
			white-space: nowrap;
			display: inline-block;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.nav-item a {
		border-bottom: 1px solid var(--tab-border-color) !important;
		line-height: 1;
		// border-top: 1px solid var(--tab-border-color) !important;
	}
}

.no-wrap {
	white-space: nowrap;
}
