@import '../abstracts/variables';

.table {
	@include shadow-1;

	thead.table-itech-thread {
		tr {
			background: var(--main-background) !important;
			color: var(--text-color) !important;
			// text-transform: uppercase;

			th {
				font-weight: 700 !important;
				text-align: left;
			}
		}

		th,
		td,
		span {
			background-color: var(--main-background);
			color: var(--text-color) !important;
			font-weight: 700 !important;
            font-size: .875rem;
            // line-height: 200%;
		}
	}
}

.table-striped {
	tbody {
		tr {
			&:nth-of-type(odd) {
				background: var(--tableOdd);

				&:hover {
					&:not(.active) {
						background: var(--table-hover-row) !important;
					}

					// box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
				}
			}

			&:nth-of-type(even) {
				&:not(.active) {
					background: var(--tableEven);
				}

				&:hover {
					&:not(.active) {
						background: var(--table-hover-row) !important;
					}

					// box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
				}
			}

			/*&:hover {
				background: $main-color-light-1;
				color: var(--white);
				box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.2);
			}*/

			// td {
				//text-align: center;
			// }

			td:has(button.btn-itech-icon) {
				padding: 0 !important;
				background: black !important;
			}

			td:has(div) {
				& > div {
					background: #dbdbdb;
				}
			}
		}

		// &:hover {
		//     background: $main-color-light-1;
		//     color: var(--white);
		// }
	}
}

.hide-bulk-actions {
	[data-test="bulk-actions-toolbar"] {
		display: none;
	}
}
