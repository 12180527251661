@import "../abstracts/variables";

.form-control {
	background-color: var(--form-background-color);
	color: var(--text-color);

	//border-width: 2px;
	//border-color: $grey-light;
	&:hover {
		border-color: var(--main-color-hover);
	}

	&:focus {
		border-color: var(--main-color-dark);
		box-shadow: none;
		background-color: var(--form-background-color-focus) !important;
		color: var(--text-color) !important;
	}

	&:disabled {
		background-color: var(--form-background-color-disabled) !important;
		color: var(--form-background-color-disabled-contrast) !important;
	}

	border: none;
	box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
	padding: 3px 8px;
}

.form-control-sm {
	font-size: 14px;
	height: 30px;
	// background-color: var(--form-background-color);
	// color: var(--form-background-color-contrast);
	border: 1px solid var(--border-color);
}

.form-group {
	margin-bottom: 0.3rem;
}

.was-validated {
	.form-control {
		&:valid ~ .invalid-feedback {
			display: none;
		}
	}
}

.input-group-itech-search {
	.input-group-prepend {
		.input-group-text {
			border-radius: 99px 0 0 99px;
			background: var(--form-background-color);
			border: var(--form-background-color);
		}
	}

	.form-control {
		border-radius: 0 99px 99px 0;
		height: 30px !important;
	}
}

.hide-legend {
	legend, p {
		display: none;
	}
}

.file-input {
	& p {
		margin: auto;
	}

	& div {
		padding: 0;
	}
}

.report-builder-form {
	> div {
		width: 100%;
		padding-left: 0;
	}
}


.custom-control-label:before{
	background-color:var(--form-background-color);
	// border-color: black;
  }

.custom-checkbox .custom-control-input:checked~.custom-control-label::before{
	background-color:var(--form-background-color);
	border-color: var(--border-color);
}

// .custom-radio .custom-control-input:checked~.custom-control-label::before{
// 	background-color:var(--main);
// 	border-color: var(--main);
// }
.custom-radio-hidden {
	position: absolute;
	opacity: 0;
}

.custom-radio {
	display: inline-block;
	position: relative;
	// padding: 0 6px;
	// margin: 10px 0 0;
}
  
.custom-radio input[type='radio'] {
	display: none;
}

.custom-radio label {
	// color: var(--text-color);
	font-weight: normal;
	font-size: 14px;
}

.custom-radio label:before {
	content: " ";
	display: inline-block;
	position: relative;
	// top: 5px;
	margin: 0 5px 0 0;
	width: 14px;
	height: 14px;
	border-radius: 11px;
	border: 2px solid var(--text-color);
	background-color: transparent;
}

.custom-radio input[type=radio]:checked + label:after {
	border-radius: 11px;
	width: 8px;
	height: 8px;
	position: absolute;
	// top: 11px;
	left: 3px;
	content: " ";
	display: inline-block;
	background: var(--main-color-dark);
}

// .custom-checkbox .custom-control-input:disabled {
// 	background-color:var(--main);
// 	border-color: var(--main);
// }

.col-form-label {
	padding-top: calc(.375rem + 1px);
    padding-bottom: calc(.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
	color: var(--text-color);
}