@import "../abstracts/variables";


/*============================================
            BUTTON ON NAVBAR
============================================*/

.simple-btn-keyboard-nav {
	@include phones {

		a,
		div[uib-dropdown] {
			padding: 10px 15px !important;
		}
	}

	@include tablets {

		a,
		div[uib-dropdown] {
			padding: 22px 15px !important;
		}
	}

	@include mediumDesktops {

		a,
		div[uib-dropdown] {
			padding: 22px 15px !important;
		}
	}

	@include largeDesktops {

		a,
		div[uib-dropdown] {
			padding: 22px 15px !important;
		}
	}
}

/*============================================
            BUTTON RE-DESIGN
============================================*/
.btn-shadow {
	box-shadow: var(--shadow);
}

.btn-itech {
	//height: 40px;
	border-radius: 5px !important;
	color: var(--default-text) !important;
	//line-height: 25px;
	//text-transform: uppercase;
	font-weight: 500;
	box-shadow: var(--shadow);
	//padding: 0.375rem 0.75rem;
	white-space: nowrap;

	&:focus {
		box-shadow: none;
	}

	&[disabled] {
		pointer-events: none;
	}

	&-fixed {
		min-width: 129px;
	}
	
	&-sm {
		min-width: 75px !important;
		//height: 31px;
		margin: 0;
		//font-weight: 300;
    }
    
    &-md {
		min-width: 100px !important;
		//height: 31px;
		margin: 0;
		//font-weight: 300;
    }
    
    &-xl {
		min-width: 129px !important;
		//height: 31px;
		margin: 0;
		//font-weight: 300;
	}
	
	&-icon {
		//min-width: 75px !important;
		//height: 31px;
		height: 26px;
		width: 26px;
		padding: unset !important;
		margin: 0 auto;
		border-radius: 99px !important;
		display: flex;
		background-color: var(--button-icon-color);
	
		svg, i {
			margin: auto;
			font-size: 14px;
		}
		//font-weight: 300;
	}
	
	&-icon-primary {
		box-shadow: none;
		color: var(--default-text) !important;
		background-color: var(--button-icon-color);
		// background: transparent !important;
	
		// i::before {
		// 	// color: var(--text-color);
		// }
	
		&:hover, &:active, &.active {
			// background: var(--main-color) !important;
			filter: brightness(110%);
	
			// i::before {
			// 	color: var(--default-text);
			// }
		}
	}
	
	&-icon-warning {
		box-shadow: none;
		// color: var(--orange) !important;
		background: transparent !important;
	
		&:hover, &:active, &.active {
			background: var(--orange) !important;
			// color: var(--white) !important;
		}
	}
	
	&-icon-secondary {
		box-shadow: none;
		color: var(--default-text) !important;
	
		&:hover, &:active, &.active {
			// background: var(--main-color-dark) !important;
			filter: brightness(110%);
		}
	}
	
	&-primary {
		background: var(--button-color-primary) !important;
		// color: var(--button-color-primary-contrast) !important;
	
		i::before {
			color: white;
		}
	
		&:hover {
			// background-color: var(--main-color-hover) !important;
			filter: brightness(110%)
		}
	}

	&-secondary {
		background: var(--button-color-secondary) !important;
		// color:  var(--button-color-secondary-contrast) !important;
	
		i::before {
			// color: var(--grey-normal);
		}
	
		&:hover {
			// background-color: var(--grey-2);
			filter: brightness(110%);
		}
	}

	&-dark {
		background:  var(--main-color-dark) !important;
		// color: var(--main-color-dark-contrast) !important;
	
		&:hover {
			filter: brightness(110%);
		}
	}

	&-light {
		background: var(--main-color-light);
		// color: var(--main-color-light-contrast);
	
		&:hover {
			// background: var(--grey-normal-opacity)-hover;
			// color: var(--cyan);
			filter: brightness(110%);
		}
	
		&:active {
			// background: var(--grey-normal-opacity);
			filter: brightness(110%);
		}
	}

	&-danger {
		background: var(--orange);
		color: var(--white) !important;
	
		&:hover {
			color: var(--white);
			background-color: var(--orange-light-1);
		}
	
		&:active {
			background: var(--grey-1);
		}
	}

	&-dropdown {
		background: var(--white);
		border: 1px solid #ced4da;
		white-space: nowrap;
	
		&:hover {
			border-color: var(--main-color-light);
		}
	
		&:focus {
			border-color: var(--main-color-dark);
			background-color: var(--form-background-color-focus) !important;
		}
    }
    
    &-delete {
        background: var(--main-color);
        // color: var(--black) !important;

        &:hover {
            color: var(--white);
			filter: brightness(110%);
            // background-color: var(--grey-normal);
        }

        &:active {
			filter: brightness(110%);
        }

    }
}

.btn-sm {
	// line-height: 1.2;
}

.btn-pick-date {
	height: 30px;
	background: var(--button-color-primary) !important;
	color: var(--default-text) !important;
	flex: 1 1 0px;
	text-overflow: ellipsis;
	white-space: nowrap;


	&.active {
		background-color: var(--button-color-secondary);
		filter: brightness(125%);
		color: var(--default-text) !important;
		z-index: 0 !important;
	}
}

.btn-itech-outline {
	height: 30px;
	// background: var(--button-color-primary) !important;
	border: 3px solid transparent;
	border-radius: 25px;
	color: var(--text-color) !important;
	flex: 1 1 0px;
	text-overflow: ellipsis;
	white-space: nowrap;
	padding: 0;

	&.active {
		// background-color: var(--text-color-contrast);
		border: 3px solid var(--button-color-primary);
		border-radius: 25px;
		padding: 0;
		// filter: brightness(125%);
		color: var(--text-color) !important;
		z-index: 0 !important;
	}
}

.group-btn-pick-date {
	display: flex;

	& > button {
		margin-left: .15rem;
		margin-right: .15rem;
		flex-grow: 1;

		&:first-child {
			margin-left: 0 !important;
		}

		&:last-child {
			margin-right: 0 !important;
		}
	}
}

.btn:disabled {
	pointer-events: none;
}

.btn-link {
	color: var(--text-color-link);
}

// .btn-redirect-create-resource{
//     width: 112px;
//     overflow: hidden;
//     white-space: nowrap;
//     text-overflow: ellipsis;
// }

.radius-0 {
	border-radius: 0 !important;
}

.radius-top-0 {
	border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important;
}

.radius-bot-0 {
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}
