@import '../abstracts/variables';

.btn-dropdown {
    background: var(--form-background-color) !important;
    color: var(--form-background-color-contrast);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(69, 95, 109, 0.5);

    &:hover,
    &:focus,
    &:active {
        background-color: var(--grey-normal) !important;
    }

    &.dropdown-toggle {
        background: var(--white);
    }
}

.dropdown-itech a {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: var(--text-color);
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    button, div {
        color: var(--text-color);
    }
    &:hover, &:active, &:active, &:focus {
        background-color: var(--dropdown-item-background-hover);
        color: var(--default-text);
        button, div {
            color: var(--default-text);
        }
    }
}

.dropdown-item {
    &.disabled, &:disabled {
        pointer-events: none;
        opacity: .65;
        color: var(--text-color);
    }
}
