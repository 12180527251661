@import '../abstracts/variables';

.delete-box {
    position: absolute;
    height: var(--delete-box-height);
    width: 100%;
    bottom: 0;
    background: var(--cyan);
    color: var(--white);
    left: 0;
}

.has-delete-box {
    margin-bottom: var(--delete-box-height);
}