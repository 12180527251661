@import '../abstracts/variables';

.label-filter {
	font-size: var(--fontSize875);
	// font-weight: bold;
	padding-bottom: 0.25rem;
	// color: var(--text-color);
}

.label-filter-sm {
	font-size: var(--fontSize875);
	// font-weight: bold;
	padding-top: 0;
	padding-bottom: 0;
	// color: var(--text-color);
}

.label-color {
	color: var(--text-color);
}

.label-info {
	font-size: 1.125rem !important;
	overflow-wrap: break-word;
}

.info-title-label {
	font-size: .8rem;
	text-transform: uppercase;
}

.label-header-xl {
	font-size: 24px;
	color: var(--text-header-color) !important;
	font-weight: 500;
}

.label-header-lg {
	font-size: 20px;
	color: var(--text-header-color) !important;
	font-weight: 500;
}

.label-header-md {
	font-size: 16px;
	color: var(--text-header-color) !important;
	font-weight: 500;
}

.label-header-sm {
	font-size: 14px;
	color: var(--text-header-color) !important;
	font-weight: 500;
}
