@import '../abstracts/mixins';
@import '../abstracts/variables';

.scroll-sm {
	@include scrollbars-size(4px);
	// @include scrollbars-color($text-color, $grey-1);
}

.scroll-secondary {
	@include scrollbars-color(var(--main), var(--main-background));
}