.main-page {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.bg-color {
	background: var(--main-background);
}

.bg-color-sidebar {
	background: var(--sidebar-background);
}
