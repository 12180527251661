@import "../abstracts/variables";

.bg-light {
	background: var(--white) !important;
}

.nav-tabs {
	border: none;
}

.navbar.nav-iTech {
	background: var(--navbar-background);
	padding: 0 1rem;

	.logo {
		height: 40px;
	}
}

.nav-item {
	user-select: none;
	// border-style: solid;
	// border-width: 1px 1px 0 1px;
	// border-color: var(--grey-background);
	height: var(--tab-height);
	// border-radius: 0 7px 0 0;
	border-radius: 0;
	background: var(--tab-background) !important;
	border-right: 1px solid var(--tab-border-color);
	border-top: 1px solid var(--tab-border-color);

	a {
		color: var(--text-color);
		-webkit-user-drag: none;

		&:hover {
			background-color: var(--tab-active-background);
		}
	}

	&:hover {
		background-color: var(--tab-active-background) !important;
	}

	.nav-link {
		// border-radius: 0 7px 0 0;
		border-radius: 0;
		height: var(--tab-height);

		&:hover {
			// border-radius: 0 7px 0 0;
			border-radius: 0;
			background-color: var(--tab-active-background);
			border-color: var(--tab-active-background);
			color: var(--text-color);
		}

		&.active {
			// border-radius: 0 7px 0 0;
			border-radius: 0;
			background-color: var(--tab-active-background);
			border-color: var(--tab-active-background);
			color: var(--text-color);
			font-weight: 700;

			// &:hover {
			// 	border-color: transparent !important;
			// }
		}
	}
}

#navItech {
	// transform: translate(0, 6px);
	//margin-left: calc(1.5rem - 1px);
	flex: 1;
	flex-wrap: nowrap;
	overflow-x: overlay;
	overflow-y: hidden;
	position: relative;


	//&:last-child {
	//    margin-left: auto;
	//}

	.tab-close {
		svg {
			margin-left: .5rem;
			//background-color: var(--cyan);
			color: var(--cyan);

			&:hover {
				border-radius: 59%;
				background-color: var(--cyan);
				color: white;
			}
		}
	}


	&::-webkit-scrollbar {
		width: 1vw;
		background: transparent;
		//display: none;
		//position: absolute;
		//bottom: 10px;
		//transform: translateY(-10px);
		//margin-top: 1rem;
	}

	&::-webkit-scrollbar-track, &::-webkit-scrollbar-track-piece {
		background: transparent
	}
}

.nav.nav-pills.nav-iTech {
	a {
		color: var(--main-color);

		&:hover {
			color: var(--main-color-hover);
			background-color: var(--green-input-form-selection);
		}

		&.active {
			color: white;
			background-color: var(--main-color);

			:hover {
				background-color: var(--green-input-form-selection);
			}
		}
	}
}
